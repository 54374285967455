import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';
import {locale as english} from 'app/main/private/common/add-photo-to-product-modal/i18n/en';
import {locale as french} from 'app/main/private/common/add-photo-to-product-modal/i18n/fr';
import {locale as indian} from 'app/main/private/common/add-photo-to-product-modal/i18n/in';
import {NZ_MODAL_DATA, NzModalRef} from 'ng-zorro-antd/modal';
import {Subject} from 'rxjs';

@Component({
    selector: 'data-viewer-modal.component',
    templateUrl: './data-viewer-modal.component.html',
    animations: fuseAnimations
})
export class DataViewerModelComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<void> = new Subject();
    headers = [];
    rows = [];
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        public modal: NzModalRef,
        @Inject(NZ_MODAL_DATA) public data: {rows: any[]}
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, french, indian);
        if (data.rows && data.rows.length !== 0) {
            for (const key of Object.keys(data.rows[0])) {
                this.headers.push(key);
            }
            this.rows = data.rows;
        }
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    getName(key: string): string {
        return key
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }
}
