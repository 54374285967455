import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MposFormsModule} from 'app/common/mpos-forms/mpos-forms.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';
import {PerfectScrollbarModule} from 'perfect-scrollbar-angular';
import {AddPhotoToProductComponent} from 'app/main/private/common/add-photo-to-product-modal/add-photo-to-product-modal.component';
import {NzFormModule} from 'ng-zorro-antd/form';
import {MposEntityModule} from 'app/common/mpos-entity/mpos-entity.module';
import {NzModalModule} from 'ng-zorro-antd/modal';

@NgModule({
    imports: [
        CommonModule,
        MposEntityModule,
        PerfectScrollbarModule,
        FlexLayoutModule,
        MposFormsModule,
        FontAwesomeModule,
        NzModalModule,
        NzFormModule
    ],
    declarations: [AddPhotoToProductComponent],
    exports: [AddPhotoToProductComponent],
    providers: [
        {
            provide: TRANSLATION_BASE,
            useValue: 'addPhotoToProduct'
        }
    ]
})
export class AddPhotoToProductModule {}
