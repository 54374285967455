import {Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {fuseAnimations} from '@fuse/animations';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';
import {ProductResource} from 'app/blocks/resource/product-resource';
import {ProductService} from 'app/blocks/service/api/product.service';
import {locale as english} from 'app/main/private/common/add-photo-to-product-modal/i18n/en';
import {locale as french} from 'app/main/private/common/add-photo-to-product-modal/i18n/fr';
import {locale as indian} from 'app/main/private/common/add-photo-to-product-modal/i18n/in';
import {NZ_MODAL_DATA, NzModalRef} from 'ng-zorro-antd/modal';
import {Observable, Subject, forkJoin, from} from 'rxjs';

@Component({
    selector: 'add-photo-to-product-modal.component',
    templateUrl: './add-photo-to-product-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AddPhotoToProductComponent implements OnInit, OnDestroy {
    faTimes = faTimes;
    images: any[];

    private _unsubscribeAll: Subject<void> = new Subject();

    form: UntypedFormGroup;
    formItems: UntypedFormArray;
    products$: Observable<any[]>;

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        public modal: NzModalRef,
        private _formBuilder: UntypedFormBuilder,
        private productResource: ProductResource,
        private productService: ProductService,
        @Inject(NZ_MODAL_DATA) public data: {images: any[]}
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, french, indian);
        this.form = this._formBuilder.group({
            items: this._formBuilder.array([])
        });
        this.formItems = this.form.get('items') as UntypedFormArray;
        this.images = data.images;
        for (const image of data.images) {
            this.formItems.push(
                this._formBuilder.group({
                    product: [],
                    name: [image.name],
                    image: [image.image64]
                })
            );
        }
        console.log(this.formItems);
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    productDisplayText = (product) => {
        let displayText = '';
        if (product) {
            displayText = displayText + '-' + product.handheldDescription;
        }
        return displayText;
    };

    searchProduct = (term): Observable<any[]> => {
        return from(
            this.productResource.api
                .searchHttp({
                    page: 0,
                    query: term,
                    size: 5000,
                    showInactive: false
                })
                .then((response) => {
                    return response.body;
                })
        );
    };

    changeProduct(selectedProduct: any, i: number): void {
        const item = this.formItems.controls[i].value;
        item.product = selectedProduct;
        this.formItems.controls[i].setValue(item);
    }

    onSave() {
        const uploadObservables = this.formItems.value.map((item) => {
            return this.productService.uploadFile(item.name, this.base64ToFile(item.image, item.name), item.product.id, []);
        });

        forkJoin(uploadObservables).subscribe({
            next: (responses) => {
                this.modal.close(responses);
            },
            error: (error) => {
                console.error('Error uploading images', error);
                this.modal.close();
            }
        });
    }

    base64ToFile(base64String: string, filename: string): File {
        const byteString = atob(base64String);

        // Write the bytes of the string to an ArrayBuffer
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
        }

        // Create a Blob from the ArrayBuffer
        const blob = new Blob([arrayBuffer], {type: 'image/png'});

        // Create and return a File object
        return new File([blob], filename, {type: 'image/png'});
    }
}
