import {Component, OnDestroy, OnInit, AfterViewInit, Input, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import {faEye, faEyeSlash, faFile, faHeadphones, faRobot, faStar, faSun, faSyncAlt} from '@fortawesome/free-solid-svg-icons';
import {SuggestionService} from 'app/blocks/service/api/suggestion.service';
import {AIInputService} from 'app/blocks/service/ai-input.service';
import {AddPhotoToProductComponent} from 'app/main/private/common/add-photo-to-product-modal/add-photo-to-product-modal.component';
import {NzModalService} from 'ng-zorro-antd/modal';
import {Subject} from 'rxjs';
import {DataViewerModelComponent} from 'app/main/private/common/data-viewer/data-viwer-model.component';
enum InputType {
    DOCUMENT = 'DOCUMENT',
    VOICE = 'VOICE',
    IMAGE = 'IMAGE',
    TEXT = 'TEXT'
}

enum ImageType {
    PRODUCT = 'PRODUCT'
}

@Component({
    selector: 'ai-input',
    template: `
        <span>
            <span *ngIf="!this.loading" class="mpos-form-row m-8">
                <fa-icon style="font-size: 24px;" class="mr-12" [icon]="faRobot" (click)="toggleShow()"></fa-icon>
                <span *ngIf="show" style="display: flex;">
                    <textarea
                        nz-input
                        type="text"
                        (keydown.Enter)="processText($event)"
                        style="border-radius: 8px;min-width:300px"
                        [placeholder]="placeholderText"
                        rows="1"></textarea>
                    <fa-icon
                        *ngIf="!disableRecording"
                        [spin]="this.isRecording"
                        style="font-size: 24px;"
                        [icon]="faHeadphones"
                        (click)="toggleRecording()"
                        class="ml-12"
                        nz-tooltip
                        nzTooltipTitle="Voice"></fa-icon>
                    <input style="display: none" type="file" id="file" size="60" (change)="onFileSelected($event)" accept=".*" #fileInputElement />
                    <span *ngIf="!commandMode">
                        <span class="file-upload" nz-tooltip nzTooltipTitle="Attach Image">
                            <fa-icon style="font-size: 24px;" [icon]="faFile" (click)="fileInputElement.click()" class="ml-12"></fa-icon>
                        </span>
                        <span
                            *ngIf="!disableObjectImageToggle"
                            style="height: 20px; width: 1px; background-color: #000; display: inline-block; margin-left: 12px;"></span>
                        <Span
                            *ngIf="!disableObjectImageToggle && objectImage"
                            nz-tooltip
                            nzTooltipTitle="Individual product photos that can be attached to existing products from the uploaded image.">
                            <fa-icon style="font-size: 16px;" [icon]="faEye" (click)="toggleObjectImage()" class="ml-12"></fa-icon
                        ></Span>
                        <Span
                            *ngIf="!disableObjectImageToggle && !objectImage"
                            nz-tooltip
                            nzTooltipTitle="Individual product photos that can be attached to existing products from the uploaded image.">
                            <fa-icon style="font-size: 16px;" [icon]="faEyeSlash" (click)="toggleObjectImage()" class="ml-12"></fa-icon
                        ></Span>
                    </span>
                </span>
            </span>
            <span *ngIf="this.loading" class="m-8">
                Getting Suggestions
                <fa-icon [icon]="faUploading" [spin]="true"></fa-icon>
            </span>
        </span>
    `
})
export class AIInputComponent implements OnInit, OnDestroy, AfterViewInit {
    private _unsubscribeAll = new Subject<void>();
    private mediaRecorder: MediaRecorder | null = null;
    isRecording = false;
    loading = false;
    language = 'en-UK';

    faUploading = faSyncAlt;
    faEye = faEye;
    faEyeSlash = faEyeSlash;
    faFile = faFile;
    faRobot = faRobot;
    faHeadphones = faHeadphones;
    objectImage = false;
    show = false;

    @Input() commandMode = false;
    @Input() entity: any;
    @Input() disableRecording: boolean = false;
    @Input() disableObjectImageToggle: boolean = true;
    @Input() directlyReturnResponse: boolean = false;
    @Input() displayError: boolean = true;
    @Input() placeholderText: string = 'Type something for AI....';

    @Output() onSuggestion: EventEmitter<any> = new EventEmitter<any>();

    toggleObjectImage() {
        this.objectImage = !this.objectImage;
    }

    toggleShow() {
        this.show = !this.show;
    }

    constructor(
        private suggestionService: SuggestionService,
        private modelService: NzModalService,
        private aIInputService: AIInputService
    ) {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {}

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    toggleRecording() {
        if (this.isRecording) {
            this.stopRecording();
        } else {
            this.startRecording();
        }
    }

    startRecording() {
        this.isRecording = true;
        navigator.mediaDevices
            .getUserMedia({audio: true})
            .then((stream) => {
                this.mediaRecorder = new MediaRecorder(stream);
                this.mediaRecorder.addEventListener('dataavailable', this.audioEvent.bind(this));
                this.mediaRecorder.start();
            })
            .catch((error) => console.error('Error accessing the microphone', error));
    }

    stopRecording() {
        this.isRecording = false;
        this.mediaRecorder?.stop();
    }

    audioEvent(event) {
        this.loading = true;
        const audioFile = new File([event.data], 'data.audio', {type: event.data.type});
        if (this.commandMode) {
            this.suggestionService.getCommand(audioFile, Object.keys(this.aIInputService.entityCommands).join(',')).then((response) => {
                this.aIInputService
                    .processCommands(audioFile, response?.commands, false)
                    .then((errors) => {
                        this.showErrors(errors);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        } else {
            this.suggestionService
                .getSuggestion(audioFile, this.entity, InputType.VOICE, this.objectImage)
                .then((response) => {
                    this.onSuggestion.emit(
                        this.directlyReturnResponse
                            ? response
                            : {
                                  suggestion: response?.suggestion,
                                  file: audioFile,
                                  errors: this.showErrors(response?.errors)
                              }
                    );
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }

    onFileSelected(event: any) {
        this.loading = true;
        if (event.target.files[0].type.includes('audio') || event.target.files[0].type.includes('video')) {
            this.suggestionService
                .getSuggestion(event.target.files[0], this.entity, InputType.VOICE, this.objectImage)
                .then((response) => {
                    this.onSuggestion.emit({
                        suggestion: response?.suggestion,
                        file: event.target.files[0],
                        errors: this.showErrors(response?.errors)
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        } else if (event.target.files[0].type.includes('image')) {
            this.suggestionService
                .getSuggestion(event.target.files[0], this.entity, InputType.IMAGE, this.objectImage)
                .then((response) => {
                    if (response.images && response.images.length > 0) {
                        this.postProcessImage(response?.images, response?.imageType);
                    }
                    this.onSuggestion.emit(
                        this.directlyReturnResponse
                            ? response
                            : {
                                  suggestion: response?.suggestion,
                                  file: event.target.files[0],
                                  errors: this.showErrors(response?.errors)
                              }
                    );
                })
                .finally(() => {
                    this.loading = false;
                });
        } else {
            this.suggestionService
                .getSuggestion(event.target.files[0], this.entity, InputType.DOCUMENT, this.objectImage)
                .then((response) => {
                    this.onSuggestion.emit(
                        this.directlyReturnResponse
                            ? response
                            : {
                                  suggestion: response?.suggestion,
                                  file: event.target.files[0],
                                  errors: this.showErrors(response?.errors)
                              }
                    );
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }

    processText(event) {
        this.loading = true;
        if (this.commandMode) {
            const command = event.target.value.toLowerCase();
            if (command.includes('queryai')) {
                const cleanedText = event.target.value
                    .replace(/[^a-zA-Z0-9,.& ]/g, '')
                    .replace('queryai', '')
                    .trim();
                this.suggestionService.getDbData(cleanedText).then((response) => {
                    this.loading = false;
                    this.modelService.create({
                        nzContent: DataViewerModelComponent,
                        nzData: {
                            rows: response
                        },
                        nzStyle: {top: '20px'},
                        nzCentered: true,
                        nzBodyStyle: {
                            height: '500px'
                        },
                        nzFooter: null
                    });
                });
                return;
            } else {
                this.suggestionService
                    .getCommandText(event.target.value, Object.keys(this.aIInputService.entityCommands).join(','))
                    .then((response) => {
                        this.aIInputService
                            .processCommands(event.target.value, response?.commands)
                            .then((errors) => {
                                this.showErrors(errors);
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    });
            }
        } else {
            this.suggestionService
                .getSuggestionText(event.target.value, this.entity)
                .then((response) => {
                    this.onSuggestion.emit(
                        this.directlyReturnResponse
                            ? response
                            : {
                                  suggestion: response?.suggestion,
                                  file: null,
                                  errors: this.showErrors(response?.errors)
                              }
                    );
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }

    postProcessImage(images: any, type: ImageType) {
        if (type === ImageType.PRODUCT) {
            const modal = this.modelService.create({
                nzContent: AddPhotoToProductComponent,
                nzData: {
                    images: images
                },
                nzWidth: 500,
                nzStyle: {top: '20px'},
                nzCentered: true,
                nzBodyStyle: {
                    height: '500px'
                },
                nzFooter: null
            });
            modal.afterClose.subscribe((result) => {});
        }
    }

    showErrors(errors): any {
        if (!errors || errors.length === 0) {
            return;
        }

        for (const error of errors) {
            error['suggestion'] = error['suggestion'].replace('null', 'n/a');
        }

        if (!this.displayError) {
            return errors;
        }

        this.modelService.create({
            nzContent: DataViewerModelComponent,
            nzData: {
                rows: errors
            },
            nzStyle: {top: '20px'},
            nzCentered: true,
            nzBodyStyle: {
                height: '500px'
            },
            nzFooter: null
        });
        return errors;
    }
}
