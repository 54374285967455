import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MposFormsModule} from 'app/common/mpos-forms/mpos-forms.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';
import {PerfectScrollbarModule} from 'perfect-scrollbar-angular';
import {NzFormModule} from 'ng-zorro-antd/form';
import {MposEntityModule} from 'app/common/mpos-entity/mpos-entity.module';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {DataViewerModelComponent} from 'app/main/private/common/data-viewer/data-viwer-model.component';

@NgModule({
    imports: [
        CommonModule,
        MposEntityModule,
        PerfectScrollbarModule,
        FlexLayoutModule,
        MposFormsModule,
        FontAwesomeModule,
        NzModalModule,
        NzFormModule
    ],
    declarations: [DataViewerModelComponent],
    exports: [DataViewerModelComponent],
    providers: [
        {
            provide: TRANSLATION_BASE,
            useValue: 'dataViewer'
        }
    ]
})
export class DataViewerModelModule {}
