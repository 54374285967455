<div class="di-modal-header">
    <button nz-button nzType="default" (click)="modal.close()"></button>
</div>
<div style="overflow-y: scroll !important; max-height: 340px; min-height: 340px; padding-top: 10px; padding-bottom: 10px">
    <div class="di-modal-body">
        <div class="di-table-pane">
            <div class="block-overflow">
                <nz-table #dynamicTable [nzData]="rows" [nzBordered]="false" [nzSimple]="false" [nzShowPagination]="false">
                    <thead>
                        <tr>
                            <th *ngFor="let key of headers; let i = index">{{ getName(key) }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of rows">
                            <td *ngFor="let key of headers; let i = index">{{ row[key] }}</td>
                        </tr>
                    </tbody>
                </nz-table>
            </div>
        </div>
    </div>
</div>
