<div class="di-modal-header" fxLayout="row" fxLayoutAlign="end center">
    <button nz-button nzType="default" (click)="modal.close()"></button>
</div>
<h1>Add Images to Products</h1>
<div style="overflow-y: scroll !important; max-height: 340px; min-height: 340px; padding-top: 10px; padding-bottom: 10px">
    <div class="di-modal-body">
        <div [formGroup]="form" class="col-12 col oh">
            <div *ngFor="let item of formItems.controls; let i = index">
                <div [formGroup]="formItems.controls[i]" class="mpos-form-row" style="align-items: center; display: flex">
                    <figure>
                        <img [src]="'data:image/png;base64,' + item.value.image" height="130px" width="130px" />
                        <figcaption>
                            <h2>{{ item.value.name }}</h2>
                        </figcaption>
                    </figure>
                    <mpos-search-select
                        [grow]="true"
                        [focus]="true"
                        field="product"
                        [options$]="products$"
                        (selectionChange)="changeProduct($event, i)"
                        [customDisplayFn]="productDisplayText"
                        [customSearchFn]="searchProduct"
                        [idSuffix]="i.toString()">
                    </mpos-search-select>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="di-modal-footer" fxLayout="row" fxLayoutAlign="end center" style="padding-top: 20px">
    <button nz-button nzType="default" class="mx-24" (click)="onSave()">Save</button>
</div>
