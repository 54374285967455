import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzInputModule} from 'ng-zorro-antd/input';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AIInputComponent} from 'app/common/ai-input/ai-input.component';
import {AddPhotoToProductModule} from 'app/main/private/common/add-photo-to-product-modal/add-photo-to-product-modal.module';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {DataViewerModelModule} from 'app/main/private/common/data-viewer/data-viewer-modal.module';

@NgModule({
    declarations: [AIInputComponent],
    imports: [
        CommonModule,
        TranslateModule,
        NzIconModule,
        NzInputModule,
        FontAwesomeModule,
        AddPhotoToProductModule,
        NzModalModule,
        NzToolTipModule,
        DataViewerModelModule
    ],
    exports: [AIInputComponent],
    providers: []
})
export class AIInputModule {}
